import { Injectable } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Device } from '@capacitor/device';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {

  analyticsEnabled = true;

  private firebaseInitialized = false; // Custom flag to track initialization

  constructor(private router: Router) {
    this.initFirebase();

    this.router.events.pipe(
      filter((e: any) => e instanceof NavigationEnd),
    ).subscribe((e: RouterEvent) => {
      console.log('route changed: ', e.url);
      this.setScreenName(e.url)
    });
  }

  async initFirebase() {
    // if ((await Device.getInfo()).platform == 'web') {
    //   FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
    // }
    const info = await Device.getInfo();
    if (info.platform === 'web' && !this.firebaseInitialized) {
      FirebaseAnalytics.initializeFirebase(environment.firebaseConfig);
      this.firebaseInitialized = true; // Set the flag to true after initializing
      console.log('FIREBASE INITIALISED', this.firebaseInitialized)
    }

  }

  setUser(user: any) {
    FirebaseAnalytics.setUserId({
      userId: user,
    });
  }

  setProperty(name: any, value: any) {
    FirebaseAnalytics.setUserProperty({
      name: name,
      value: value,
    });
  }

  logEvent(eventName: any, eventParams: any) {
    FirebaseAnalytics.logEvent({
      name: eventName,
      params: eventParams
    });
  }
 
  setScreenName(screenName: any) {
    FirebaseAnalytics.setScreenName({
      screenName
    });
  }

  toggleAnalytics() {
    this.analyticsEnabled = !this.analyticsEnabled;
    FirebaseAnalytics.setCollectionEnabled({
      enabled: this.analyticsEnabled,
    });    
  }
}
